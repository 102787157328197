import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import About from '../../components/about'
import Services2 from '../../components/Services2'
import Pricing from '../../components/Pricing'
import FunFact from '../../components/FunFact'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Testimonial from '../../components/Testimonial'


const AboutPage =() => {
    return(
        <Fragment>
            <Navbar2/>
            <About abClass={'wpo-about-section-s2'}/>
            <Testimonial />
        </Fragment>
    )
};
export default AboutPage;
