import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage2 from '../HomePage2';
import AboutPage from '../AboutPage';
import ServicePage from '../ServicePage';
import ServicePageS2 from '../ServicePageS2';
import ServiceSinglePage from '../ServiceSinglePage';
import ProjectPage from '../ProjectPage';
import ProjectPageS2 from '../ProjectPageS2';
import ProjectSinglePage from '../ProjectSinglePage';
import PricingPage from '../PricingPage';
import TestimonialPage from '../TestimonialPage';
import BlogPage from '../BlogPage';
import BlogPageLeft from '../BlogPageLeft';
import BlogPageFullwidth from '../BlogPageFullwidth';
import BlogDetails from '../BlogDetails';
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide';
import BlogDetailsFull from '../BlogDetailsFull';
import ErrorPage from '../ErrorPage';
import ContactPage from '../ContactPage';
import LoginPage from '../LoginPage';
import SignUpPage from '../SignUpPage';
import ForgotPassword from '../ForgotPassword';

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Homepage2 />} />
          <Route path='/home' element={<Homepage2 />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/service' element={<ServicePage />} />
          <Route path='/service-s2' element={<ServicePageS2 />} />
          <Route path='/service-single' element={<ServiceSinglePage />} />
          <Route path='/project' element={<ProjectPage />} />
          <Route path='/project-s2' element={<ProjectPageS2 />} />
          <Route path='/project-single' element={<ProjectSinglePage />} />
          <Route path='/pricing' element={<PricingPage />} />
          <Route path='/testimonial' element={<TestimonialPage />} />
          <Route path='/404' element={<ErrorPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/blog' element={<BlogPage />} />
          <Route path='/blog-left-sidebar' element={<BlogPageLeft />} />
          <Route path='/blog-fullwidth' element={<BlogPageFullwidth />} />
          <Route path='/blog-single' element={<BlogDetails />} />
          <Route path='/blog-single-left-sidebar' element={<BlogDetailsLeftSiide />} />
          <Route path='/blog-single-fullwidth' element={<BlogDetailsFull />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/register' element={<SignUpPage />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
