import React from 'react';

import srvimg1 from '../../images/icon/clipboard.svg';
import srvimg2 from '../../images/icon/briefcase.svg';
import srvimg3 from '../../images/icon/chart.svg';
import './services.css';

const Services = () => {
  const service = [
    {
      sIcon: srvimg1,
      title: 'Automation',
      des: 'Implementing cutting-edge automation solutions to streamline your operations and enhance productivity.',
      link: '/service-single',
    },
    {
      sIcon: srvimg2,
      title: 'AI Chatbots',
      des: 'Integrating AI chatbots to provide intelligent, automated customer support and engagement.',
      link: '/service-single',
    },
    {
      sIcon: srvimg3,
      title: 'Website Creation',
      des: 'Designing and developing modern, responsive websites to establish your online presence and engage your audience.',
      link: '/service-single',
    },
  ];

  return (
    <section className="wpo-service-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="wpo-section-title">
              <span></span>
              <h2>Explore Our Services</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {service.map((service, sitem) => (
            <div className="col-lg-4 col-md-6 col-12" key={sitem}>
              <div className="wpo-service-item">
                <div className="wpo-service-icon">
                  <div className="icon">
                    <img src={service.sIcon} alt="" />
                  </div>
                </div>
                <div className="wpo-service-text">
                  <h2>{service.title}</h2>
                  <p>{service.des}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;