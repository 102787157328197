import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo2.png';
import './footer.css'; // Ensure the CSS file is correctly imported

const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Link onClick={ClickHandler} to="/">
                                        <img src={Logo} alt="blog" />
                                    </Link>
                                </div>
                                <p>Connecting the right expertise to streamline your business operations. Schedule your free consultation today to discover how we can save your company time and boost efficiency.</p>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact</h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-send"></i>support@scalemytech.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* Additional footer sections can be added here if needed */}
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Copyright &copy; 2024 Scalable Technology Solutions. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
