// ContactPage.js
import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import Contactpage from '../../components/Contactpage';
import Footer from '../../components/footer';

const ContactPage = () => {
  return (
    <Fragment>
      <Navbar2 />
      <Contactpage />
      <Footer />
    </Fragment>
  );
};

export default ContactPage;
