import React, { Component } from "react";
import { Link } from 'react-router-dom';
import PlaceholderImage from '../../images/teamimage.png';
import '../../css/hero2.css';

class Hero2 extends Component {
    render() {
        return (
            <section className="wpo-hero-section-1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-xs-12 col-lg-7">
                            <div className="wpo-hero-section-text">
                                <div className="wpo-hero-title-top">
                                    <span>We bring the right people together.</span>
                                </div>
                                <div className="wpo-hero-title">
                                    <h2>Saving your <span>Business</span> Time</h2>
                                </div>
                                <div className="wpo-hero-subtitle">
                                    <p> Learn How We Can Guarantee Time Saved For Your Company<br />(Or You Don't Pay).</p>
                                </div>
                                <div className="btns">
                                    <Link to="/contact" className="btn theme-btn">Get Started</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xs-12 col-lg-5">
                            <div className="right-img">
                                <img src={PlaceholderImage} alt="Team Collaboration" className="hero-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Hero2;
