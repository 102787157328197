import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const menus = [
    { id: 1, title: 'Home', link: '/' },
    { id: 2, title: 'About', link: '/about' },
    { id: 3, title: 'Services', link: '/service' },
    { id: 4, title: 'Contact', link: '/contact' },
];

export default class MobileMenu extends Component {
    state = {
        isMenuShow: false,
    };

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        });
    };

    render() {
        const { isMenuShow } = this.state;

        const ClickHandler = () => {
            window.scrollTo(10, 0);
        };

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="menu-close">
                        <div className="clox" onClick={this.menuHandler}><i className="ti-close"></i></div>
                    </div>

                    <ul className="responsivemenu">
                        {menus.map(item => (
                            <li key={item.id}>
                                <Link onClick={ClickHandler} to={item.link}>{item.title}</Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={`showmenu ${isMenuShow ? 'hidden' : ''}`} onClick={this.menuHandler}>
                    <button type="button" className="navbar-toggler open-btn">
                        <div className="icon-bar"></div>
                        <div className="icon-bar"></div>
                        <div className="icon-bar"></div>
                    </button>
                </div>
            </div>
        );
    }
}
