import React from 'react';
import CalendlyEmbed from '../calendly'; // Adjust the path based on your directory structure
import '../../css/contactpage.css'; // Import the CSS file

const Contactpage = () => {
  return (
    <div className="wpo-contact-pg-section">
      <div className="wpo-contact-form-area">
        <div className="consultation-text">Book Below Your Free Consultation Session To Learn How We Can Guarantee Time <br /> Saved For Your Company (Or You Don't Pay)</div>
        <CalendlyEmbed />
      </div>
      <div style={{ height: '150px' }}></div> {/* Spacer div */}
    </div>
  );
};

export default Contactpage;
